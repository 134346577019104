<template>
  <div id="home" v-cloak>
    <div class="swiper" style="height:350px;">
      <a-carousel arrows style="height:350px;display:flex;width:100%;">
        <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px; zindex: 1">
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
          <a-icon type="right-circle" />
        </div>
        <div v-for="(item, index) in imgUrls" :key="index" style="height:350px;">
          <img v-lazy="item.url" :key="item.url" alt="" style="height:350px !important;" />
        </div>
      </a-carousel>
    </div>
    <div class="content">
      <div class="news-roll" v-if="longitudinalData.length > 0">
        <a-carousel
          :dots="false"
          autoplay
          :autoplaySpeed="5000"
          :speed="4000"
          :dot-position="dotPosition"
        >
          <div v-for="(item, index) in longitudinalData" :key="index">
            <div class="list-some">
              <img class="img" :src="warningUrl" />
              <div style="flex: 1">
                <p class="title">{{ item.EnterpriseMC }}-{{ item.station_name }}</p>
                <p class="text" style="display: flex">
                  <span style="flex: 1">{{ item.AlarmMsg }}</span>
                  <span>{{ item.BJSJ }}</span>
                </p>
              </div>
            </div>
          </div>
        </a-carousel>
      </div>
      <div class="transmission-rate" v-if="transmissionData">
        <div class="title">数据传输率</div>
        <div class="jizhan">{{ transmissionData.Number }}个基站的传输数据率</div>
        <div class="proportion">{{ transmissionData.Tran_Rate }}%</div>
        <router-link to="/main/transmission">
          <div class="more">
            更多
            <a-icon type="right" />
          </div>
        </router-link>
      </div>
      <div class="base-station" v-if="abnormalData">
        <div class="base-station-shell">
          <div class="uniform-title-shell" style="margin-bottom: 20px">
            <div class="uniform-title">基站数据报警</div>
            <router-link to="/main/abnormal">
              <div class="more">
                更多
                <a-icon type="right" />
              </div>
            </router-link>
          </div>
          <div class="unified-left-icon" style="margin-bottom: 24px">
            <div class="border"></div>
            <div class="text">
              {{ abnormalData.Enterprise_num }}个企业共{{
                abnormalData.Station_num
              }}个基站｜报警=异常+超标+预警
            </div>
          </div>
          <div class="base-station-list">
            <div class="call-the-police">
              <div class="number">{{ abnormalData.Total }}<span>个</span></div>
              <p class="written">报警</p>
              <img class="baseUrl" :src="baseUrl" />
            </div>
            <div class="other">
              <div class="one-of-them">
                <div class="number">{{ abnormalData.Abnormal_num }}<span>个</span></div>
                <div class="expression">
                  <img :src="imgUrl1" alt="" />
                  <p class="written">异常</p>
                </div>
              </div>
              <div class="border"></div>
              <div class="one-of-them">
                <div class="number">{{ abnormalData.Overproof_num }}<span>个</span></div>
                <div class="expression">
                  <img :src="imgUrl2" alt="" />
                  <p class="written">超标</p>
                </div>
              </div>
              <div class="border"></div>
              <div class="one-of-them">
                <div class="number">{{ abnormalData.Early_num }}<span>个</span></div>
                <div class="expression">
                  <img :src="imgUrl3" alt="" />
                  <p class="written">预警</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="data-analysis" v-show="Analysis">
        <div class="uniform-title-shell" style="margin-bottom: 20px">
          <div class="uniform-title">数据分析报告</div>
          <div class="analysis-time">
            <div class="select-time">
              <!-- <a-icon type="left" style="margin: 0 10px;color: #d9d9d9;" @click="onForward" /> -->
              <a-date-picker
                :defaultValue="moment(new Date())"
                :format="dateFormat"
                style="width: 120px"
                size="small"
                @change="onChange"
              />
              <!-- <a-icon style="margin: 0 10px;color: #d9d9d9;" @click="onBackOff" type="right" /> -->
            </div>
          </div>
          <router-link to="/main/analysis">
            <div class="more">
              更多
              <a-icon type="right" />
            </div>
          </router-link>
        </div>
        <div class="unified-left-icon" style="margin-bottom: 20px">
          <div class="border"></div>
          <div>日数据分析总报表</div>
          <div class=""></div>
          <div style="flex: 1"></div>

          <div class="tou">
            <div class="some-one">
              <div class="color" style="background: #ff0000"></div>
              <div class="text">超标</div>
            </div>
            <div class="some-one">
              <div class="color" style="background: #800080"></div>
              <div class="text">异常</div>
            </div>
            <div class="some-one">
              <div class="color" style="background: #63a6ff"></div>
              <div class="text">缺数</div>
            </div>
            <div class="some-one">
              <div class="color" style="background: #828282"></div>
              <div class="text">停运</div>
            </div>
            <div class="some-one">
              <div class="color" style="background: #63c972"></div>
              <div class="text">正常</div>
            </div>
            <!-- <img class="all-img" :src="touUrl[9]" alt="" /> -->
          </div>
        </div>
        <div class="data-analysis-echarts">
          <div id="Echarts1"></div>
          <div class="echart-shell">
            <div class="echart" id="Echarts2"></div>
            <div class="echart-legend-title">
              <img :src="touUrl[5]" alt="" />
              <p>废气基站</p>
            </div>
          </div>
          <div class="echart-shell">
            <div class="echart" id="Echarts3"></div>
            <div class="echart-legend-title">
              <img :src="touUrl[6]" alt="" />
              <p>废水基站</p>
            </div>
          </div>
          <div class="echart-shell">
            <div class="echart" id="Echarts4"></div>
            <div class="echart-legend-title">
              <img :src="touUrl[7]" alt="" />
              <p>扬尘基站</p>
            </div>
          </div>
          <div class="echart-shell">
            <div class="echart" id="Echarts5"></div>
            <div class="echart-legend-title">
              <img :src="touUrl[8]" alt="" />
              <p>VOCs基站</p>
            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="uniform-title-shell" style="margin-bottom: 24px">
          <div class="uniform-title">
            总量查询
            <div style="margin-left: 20px; display: none">
              <base-select v-bind:type="1" @baseChange="handleChange"></base-select>
            </div>
          </div>
          <router-link to="/main/total">
            <div class="more">
              更多
              <a-icon type="right" />
            </div>
          </router-link>
        </div>
        <a-table
          size="small"
          :columns="columns"
          :pagination="false"
          :data-source="data"
          bordered
        >
          <template slot="useTotal" slot-scope="useTotal">
            <div class="utilization-text">
              <div class="utilization-shell">
                <div
                  v-if="useTotal >= 0 && useTotal <= 100"
                  class="utilization"
                  :style="'width:' + useTotal + '%;'"
                ></div>
                <div v-else class="utilization" :style="'width:100%;'"></div>
              </div>
              <span>{{ useTotal }}</span>
            </div>
          </template>
        </a-table>
      </div>
      <div class="outage-base" v-if="baseData">
        <div class="uniform-title-shell" style="margin-bottom: 20px">
          <div class="uniform-title">停运基站</div>
          <router-link to="/main/stop">
            <div class="more">
              更多
              <a-icon type="right" />
            </div>
          </router-link>
        </div>
        <div class="unified-left-icon" style="margin-bottom: 29px">
          <div class="border"></div>
          <div class="text">
            {{ baseData.Data[0].Enterprise_num }}个企业共{{
              baseData.Data[0].Station_num
            }}个基站停运
          </div>
          <!-- <router-link v-if="LX == 2 || LX == 3" to="/main/addbase">
            <div style="margin-right: 20px">
              <a-button type="primary" size="small">添加基站</a-button>
            </div>
          </router-link> -->
          <div class="base-station-type">
            <span>基站类型</span>
            <base-select v-bind:type="2" @baseChange="handleChange"></base-select>
          </div>
        </div>
        <div class="outage-base-table">
          <div class="some" v-for="(item, index) in baseData.Data1" :key="index">
            <div class="icon">
              <img v-if="item.station_type == 1" :src="imgurlData[0].url1" alt="" />
              <img v-else-if="item.station_type == 2" :src="imgurlData[0].url2" alt="" />
              <img v-else-if="item.station_type == 3" :src="imgurlData[0].url3" alt="" />
              <img v-else-if="item.station_type == 4" :src="imgurlData[0].url4" alt="" />
            </div>
            <div class="station_name">
              {{ item.EnterpriseMC }}-{{ item.station_name }}
            </div>
            <div class="TYYY">停运原因： {{ item.TYYY }}</div>
            <div class="TYSD">停运时段：{{ item.TYSD }}</div>
          </div>
          <a-empty v-if="baseData.Data1.length <= 0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from "../../components/BaseSelect.vue";
import { http } from "../../utils/Http.js";

import moment from "moment";
import "moment/locale/zh-cn";

import dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

// @ is an alias to /srcx
const columns = [
  {
    title: "基站类型",
    align: "center",
    dataIndex: "station_TypeName",
  },
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    align: "center",
  },
  {
    title: "基站名称",
    align: "center",
    dataIndex: "station_name",
  },
  {
    title: "综合排放量(kg)",
    align: "center",
    dataIndex: "dischargeTotal",
  },
  {
    title: "使用率(%)",
    align: "center",
    width: 220,
    dataIndex: "useTotal",
    scopedSlots: {
      customRender: "useTotal",
    },
  },
  {
    title: "税收合计(元)",
    align: "center",
    dataIndex: "TaxAmountTotal",
  },
];

export default {
  name: "Home",
  components: {
    BaseSelect,
  },
  data() {
    return {
      LX: this.$store.state.loggedIn.LX,
      //轮播图
      imgUrls: [
        {
          url: require("../../assets/img/swiper/b1.png"),
        },
        {
          url: require("../../assets/img/swiper/b1.png"),
        },
        {
          url: require("../../assets/img/swiper/b1.png"),
        },
      ],

      //报警滚动列表信息
      dotPosition: "left",
      autoplay: true,
      warningUrl: require("../../assets/img/pics/001.png"),
      longitudinalData: [],

      //数据传输率
      transmissionData: null,

      //基站数据异常
      abnormalData: null,
      baseUrl: require("../../assets/img/pics/002.png"),
      imgUrl1: require("../../assets/img/pics/003.png"),
      imgUrl2: require("../../assets/img/pics/004.png"),
      imgUrl3: require("../../assets/img/pics/005.png"),

      //图表-数据分析报告
      Analysis: true,

      touUrl: [
        require("../../assets/img/pics/008.png"),
        require("../../assets/img/pics/009.png"),
        require("../../assets/img/pics/010.png"),
        require("../../assets/img/pics/011.png"),
        require("../../assets/img/pics/012.png"),

        require("../../assets/img/pics/024.png"),
        require("../../assets/img/pics/025.png"),
        require("../../assets/img/pics/026.png"),
        require("../../assets/img/pics/027.png"),

        require("../../assets/img/pics/028.png"),
      ],
      moment,
      dateFormat: "YYYY-MM-DD",
      TOTALDAY: dayjs(new Date()).format("YYYY-MM-DD"), //统计时间

      //表格-总量查询
      data: [],
      columns,

      //停运基站
      imgurlData: [
        {
          url1: require("../../assets/img/pics/006.png"),
          url2: require("../../assets/img/pics/007.png"),
          url3: require("../../assets/img/pics/046.png"),
          url4: require("../../assets/img/pics/047.png"),
        },
      ],
      baseData: null,
    };
  },

  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    console.log(to);
    if (to.name == "Stop") {
      this.$store.commit("setJZMC", "");
      this.$store.commit("setTYJZKSSJ", this.$store.state.yesterday);
      this.$store.commit("setTYJZJSSJ", this.$store.state.JSSJ);
    }
    next();
  },

  created() {
    //轮播图
    this.HJJC_HBJ_LBTLIST();

    //报警滚动列表
    this.HJJC_ALAMABSTRACT_ROLL();

    //基站传输率摘要
    this.HJJC_STATION_TRANSMISSION();

    // 基站数据异常摘要
    this.HJJC_STATION_DATA_ABNORMAL();

    //企业总量查询
    this.HJJC_Enterprise_Aggregate_Query_Ext(0);

    // 基站停运摘要
    this.HJJC_STATION_OUTAGE("");

    //图表
    this.HJJC_DAYTOTAL();
  },

  mounted() {},

  methods: {
    handleChange(item, type) {
      // console.log(item);
      // console.log(type);
      if (type == 1) {
        //总量查询
        this.HJJC_Enterprise_Aggregate_Query_Ext(item.FieldBH);
      } else if (type == 2) {
        //停运基站
        this.HJJC_STATION_OUTAGE(item.FieldBH);
      }
    },

    //选择日期
    onChange(date, value) {
      //console.log(value);
      this.TOTALDAY = value;
      this.HJJC_DAYTOTAL();
    },

    //日期往前
    onForward() {
      // console.log(this.selectDate);
    },

    //日期向后
    onBackOff() {
      // console.log(this.selectDate);
    },

    //首页企业总量查询
    HJJC_Enterprise_Aggregate_Query_Ext(station_Type) {
      var me = this;
      http({
        url: "/data/q30/HJJC_Enterprise_Aggregate_Query_Ext.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          EnterpriseMC: "",
          station_name: "",
          station_Type: station_Type,
          KSSJ: "",
          JSSJ: "",
          PAGESIZE: 20,
          PAGENUMBER: 1,
        },
        success(obj) {
          console.log(obj);
          for (var i = 0; i < obj.Data.length; i++) {
            obj.Data[i].key = i + 1;
          }
          me.data = obj.Data;
        },
        failure(obj) {},
        me,
      });
    },

    //轮播图
    HJJC_HBJ_LBTLIST() {
      var me = this;
      var EnterpriseBH=me.$store.state.loggedIn.EnterpriseBH;
      http({
        url: "/data/HJJC_HBJ_LBTLIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          EnterpriseBH: EnterpriseBH,
        },
        success(obj) {
          console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            me.imgUrls = [];
            for (var i = 0; i < obj.Data.length; i++) {
              me.imgUrls.push({
                url: `/data/ShowImage.ashx?guid=${obj.Data[i].Picture}`,
              });
            }
          }
        },
        failure(obj) {},
        me,
      });
    },

    //报警滚动列表
    HJJC_ALAMABSTRACT_ROLL() {
      var me = this;
      http({
        url: "/data/q30/HJJC_ALAMABSTRACT_ROLL.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          ITEM: "8",
        },
        success(obj) {
          // console.log(obj);
          me.longitudinalData = obj.Data;
        },
        failure(obj) {},
        me,
      });
    },

    //基站传输率摘要
    HJJC_STATION_TRANSMISSION() {
      var me = this;
      http({
        url: "/data/q30/HJJC_STATION_TRANSMISSION.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
        },
        success(obj) {
          // console.log(obj);
          me.transmissionData = obj.Data[0];
        },
        failure(obj) {},
        me,
      });
    },

    // 基站数据异常摘要
    HJJC_STATION_DATA_ABNORMAL() {
      var me = this;
      http({
        url: "/data/q30/HJJC_STATION_DATA_ABNORMAL.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
        },
        success(obj) {
          // console.log(obj);
          me.abnormalData = obj.Data[0];
        },
        failure(obj) {},
        me,
      });
    },

    //基站停运摘要
    HJJC_STATION_OUTAGE(station_type) {
      let me = this;
      let type = station_type || "";
      http({
        url: "/data/q30/HJJC_STATION_OUTAGE.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          station_type: type,
          ITEM: 10,
        },
        success(obj) {
          console.log(obj);
          me.baseData = obj;
        },
        failure(obj) {},
        me,
      });
    },

    //日统计接口
    HJJC_DAYTOTAL() {
      var me = this;
      http({
        url: "/data/q30/HJJC_DAYTOTAL.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: me.TOTALDAY,
        },
        success(obj) {
          console.log(obj);

          if (obj.Data2 && obj.Data2.length > 0) {
            me.Analysis = true;
          } else {
            me.Analysis = false;
          }

          if (obj.Data && obj.Data.length > 0) {
            me.Analysis = true;
          } else {
            me.Analysis = false;
          }

          console.log(me.Analysis);

          me.Echarts1(obj.Data2);
          // me.Echarts1([]);

          var data2 = new Array(); //废气基站
          var data3 = new Array(); //废水基站
          var data4 = new Array(); //扬尘基站
          var data5 = new Array(); //VOCS基站
          console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            for (var i = 0; i < obj.Data.length; i++) {
              if (obj.Data[i].FQ > 0) {
                data2.push({
                  FQ: obj.Data[i].FQ,
                  type: obj.Data[i].type,
                });
              }
              if (obj.Data[i].FS > 0) {
                data3.push({
                  FS: obj.Data[i].FS,
                  type: obj.Data[i].type,
                });
              }
              if (obj.Data[i].YC > 0) {
                data4.push({
                  YC: obj.Data[i].YC,
                  type: obj.Data[i].type,
                });
              }

              if (obj.Data[i].VO > 0) {
                data5.push({
                  VO: obj.Data[i].VO,
                  type: obj.Data[i].type,
                });
              }
            }
          }
          if (data2.length == 0) {
            data2.push({
              FQ: "",
              type: 0,
            });
          }
          if (data3.length == 0) {
            data3.push({
              FS: "",
              type: 0,
            });
          }
          if (data4.length == 0) {
            data4.push({
              YC: "",
              type: 0,
            });
          }
          if (data5.length == 0) {
            data5.push({
              VO: "",
              type: 0,
            });
          }

          me.Echarts2(data2);
          me.Echarts3(data3);
          me.Echarts4(data4);
          me.Echarts5(data5);
        },
        failure(obj) {},
        me,
      });
    },

    Echarts1(data) {
      console.log(data);
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts1"));
      var option = null;
      console.log(data);
      let seriesData = [];
      var colorList = ["#0054A7", "#EF8300", "#009EA8", "#CD41FF"];
      for (let i = 0; i < data.length; i++) {
        if (data[i].JZS != 0) {
          switch (Number(data[i].FieldBH)) {
            case 1:
              seriesData.push({
                name: `${data[i].FieldMC}`,
                value: data[i].JZS,
                itemStyle: {
                  color: "#0054A7",
                },
              });
              break;
            case 2:
              seriesData.push({
                name: `${data[i].FieldMC}`,
                value: data[i].JZS,
                itemStyle: {
                  color: "#EF8300",
                },
              });
              break;
            case 3:
              seriesData.push({
                name: `${data[i].FieldMC}`,
                value: data[i].JZS,
                itemStyle: {
                  color: "#009EA8",
                },
              });
              break;
            case 4:
              seriesData.push({
                name: `${data[i].FieldMC}`,
                value: data[i].JZS,
                itemStyle: {
                  color: "#CD41FF",
                },
              });
              break;
          }
        }
      }
      // var colorList = ['#FF0000', '#800080', '#63A6FF', '#E46363', '#63C972'];

      option = {
        title: {
          text: "基站类型",
          x: "center",
          y: "center",
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["53%", "76%"],
            left: 0,
            avoidLabelOverlap: true,
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       return colorList[params.dataIndex];
            //     },
            //   },
            // },

            labelLine: {
              normal: {
                length: 10,
                length2: 10,
                lineStyle: {
                  width: 1,
                },
              },
            },
            label: {
              show: true,
              formatter: "{b}: {c}",
            },
            data: seriesData,
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    Echarts2(data) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts2"));
      var option = null;
      var me = this;
      var colorList = ["#63C972", "#FF0000", "#800080", "#63A6FF", "#828282"];

      option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        // legend: {
        // 	selectedMode: false, // 取消图例上的点击事件
        // 	left: 'center',
        // 	top: '186px',
        // 	data: [{
        // 		name: '废气基站',
        // 		icon: 'circle'
        // 	}],
        // 	itemHeight: 16,
        // 	textStyle: {
        // 		fontSize: 16,
        // 	}
        // },
        series: [
          {
            name: "废气基站",
            top: "10px",
            type: "pie",
            radius: "80%",
            label: {
              show: false,
              normal: {
                position: "inner",
                formatter: "{b}{c}",
              },
            },
            center: ["50%", "40%"],
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.data.type];
                },
              },
            },
            data: me.getPieData(data, "FQ"),
            //  [
            //   {
            //     value: data[1].FQ,
            //     name: "超标",
            //   },
            //   {
            //     value: data[2].FQ,
            //     name: "异常",
            //   },
            //   {
            //     value: data[3].FQ,
            //     name: "缺数",
            //   },
            //   {
            //     value: data[4].FQ,
            //     name: "停运",
            //   },
            //   {
            //     value: data[0].FQ,
            //     name: "正常",
            //   },
            // ],
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    Echarts3(data) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts3"));
      var option = null;
      var me = this;
      var colorList = ["#63C972", "#FF0000", "#800080", "#63A6FF", "#828282"];

      option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        // legend: {
        // 	selectedMode: false, // 取消图例上的点击事件
        // 	left: 'center',
        // 	top: '186px',
        // 	data: [{
        // 		name: '废水基站',
        // 		icon: 'circle'
        // 	}],
        // 	itemHeight: 16,
        // 	textStyle: {
        // 		fontSize: 16,
        // 	}
        // },
        series: [
          {
            name: "废水基站",
            top: "10px",
            type: "pie",
            radius: "80%",
            label: {
              show: false,
              normal: {
                position: "inner",
                formatter: "{b}{c}",
              },
            },
            center: ["50%", "40%"],
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.data.type];
                },
              },
            },
            data: me.getPieData(data, "FS"),
            // [
            //   {
            //     value: data[1].FS,
            //     name: "超标",
            //   },
            //   {
            //     value: data[2].FS,
            //     name: "异常",
            //   },
            //   {
            //     value: data[3].FS,
            //     name: "缺数",
            //   },
            //   {
            //     value: data[4].FS,
            //     name: "停运",
            //   },
            //   {
            //     value: data[0].FS,
            //     name: "正常",
            //   },
            // ],
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    Echarts4(data) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts4"));
      var option = null;
      var me = this;
      var colorList = ["#63C972", "#FF0000", "#800080", "#63A6FF", "#828282"];

      option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        // legend: {
        // 	selectedMode: false, // 取消图例上的点击事件
        // 	left: 'center',
        // 	top: '186px',
        // 	data: [{
        // 		name: '扬尘基站',
        // 		icon: 'circle'
        // 	}],
        // 	itemHeight: 16,
        // 	textStyle: {
        // 		fontSize: 16,
        // 	}
        // },
        series: [
          {
            name: "扬尘基站",
            top: "10px",
            type: "pie",
            radius: "80%",
            label: {
              show: false,
              normal: {
                position: "inner",
                formatter: "{b}{c}",
              },
            },
            center: ["50%", "40%"],
            itemStyle: {
              normal: {
                color: function (params) {
                  console.log(params);
                  return colorList[params.data.type];
                },
              },
            },
            data: me.getPieData(data, "YC"),
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    Echarts5(data) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("Echarts5"));
      var option = null;
      var me = this;
      var colorList = ["#63C972", "#FF0000", "#800080", "#63A6FF", "#828282"];

      option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        // legend: {
        // 	selectedMode: false, // 取消图例上的点击事件
        // 	left: 'center',
        // 	top: '186px',
        // 	data: [{
        // 		name: 'VOCS基站',
        // 		icon: 'circle'
        // 	}],
        // 	itemHeight: 16,
        // 	textStyle: {
        // 		fontSize: 16,
        // 	}
        // },
        series: [
          {
            name: "VOCs基站",
            top: "10px",
            type: "pie",
            radius: "80%",
            label: {
              show: false,
              normal: {
                position: "inner",
                formatter: "{b}{c}",
              },
            },
            center: ["50%", "40%"],
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.data.type];
                },
              },
            },
            data: me.getPieData(data, "VO"),
            // [
            //   {
            //     value: data[1].VO,
            //     name: "超标",
            //   },
            //   {
            //     value: data[2].VO,
            //     name: "异常",
            //   },
            //   {
            //     value: data[3].VO,
            //     name: "缺数",
            //   },
            //   {
            //     value: data[4].VO,
            //     name: "停运",
            //   },
            //   {
            //     value: data[0].VO,
            //     name: "正常",
            //   },
            // ],
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },

    //获取图标数据
    getPieData(data, name) {
      let datas = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].type == 2) {
          datas.push({
            value: data[i][name],
            name: "超标",
            type: 1,
          });
        } else if (data[i].type == 3) {
          datas.push({
            value: data[i][name],
            name: "异常",
            type: 2,
          });
        } else if (data[i].type == 4) {
          datas.push({
            value: data[i][name],
            name: "缺数",
            type: 3,
          });
        } else if (data[i].type == 5) {
          datas.push({
            value: data[i][name],
            name: "停运",
            type: 4,
          });
        } else if (data[i].type == 1) {
          datas.push({
            value: data[i][name],
            name: "正常",
            type: 0,
          });
        } else if (data[i].type == 0) {
          datas.push({
            value: "0",
            name: "",
            type: 0,
          });
        }
      }
      return datas;
    },
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}

#home {
  background-color: rgb(242, 242, 242);
  color: #181818;
  height: 100%;

  .content {
    width: 1200px;
    margin: 20px auto 0;

    .news-roll {
      background-color: #ffffff;
      height: 80px;
      margin-bottom: 12px;
      overflow: hidden;

      .list-some {
        padding: 16px 40px;
        display: flex;
        align-items: center;

        .img {
          width: 48px;
          height: 48px;
          margin-right: 40px;
        }

        .title {
          line-height: 18px;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .text {
          line-height: 16px;
          font-size: 14px;
        }
      }
    }

    .transmission-rate {
      margin-bottom: 12px;
      height: 73px;
      background: #ffffff;
      padding: 0 40px;
      display: flex;
      align-items: center;

      .title {
        font-size: 18px;
        font-weight: 700;
        margin-right: 40px;
      }

      .jizhan {
        font-size: 16px;
        margin-right: 20px;
      }

      .proportion {
        font-size: 16px;
        font-weight: 700;
        color: #027aff;
        flex: 1;
      }

      .more {
        font-size: 14px;
        color: #999999;
        cursor: pointer;
      }
    }

    .base-station {
      height: 325px;
      margin-bottom: 12px;
      background-color: #ffffff;

      .base-station-shell {
        padding: 24px 40px;

        .base-station-list {
          display: flex;
          align-items: center;
          background: #ffffff;

          .call-the-police {
            width: 265px;
            height: 186px;
            box-shadow: 5px 5px 20px 0px #eeeeee;
            border-radius: 10px;
            position: relative;
            text-align: center;

            .number {
              height: 122px;
              font-size: 48px;
              line-height: 58px;
              padding: 32px 0 0 0;

              span {
                font-size: 24px;
              }
            }

            .written {
              height: 24px;
              font-size: 24px;
              color: #3f3f3f;
              line-height: 24px;
            }

            .baseUrl {
              width: 76px;
              height: 76px;
              position: absolute;
              top: 0;
              right: 0;
            }
          }

          .other {
            flex: 1;
            display: flex;
            align-items: center;
            height: 186px;
            box-shadow: 5px 5px 20px 0px #eeeeee;
            border-radius: 0px 10px 10px 10px;
            border-top: 1px solid #eeeeee;
            border-right: 1px solid #eeeeee;
            border-bottom: 1px solid #eeeeee;

            .border {
              width: 1px;
              height: 105px;
              background: linear-gradient(180deg, #fff 0%, #b7b7b7 50%, #fff 100%);
            }

            .one-of-them {
              flex: 1;
              text-align: center;
              height: 100%;

              .number {
                height: 126px;
                font-size: 48px;
                line-height: 58px;
                padding: 36px 0 0 0;

                span {
                  font-size: 24px;
                }
              }

              .expression {
                height: 24px;
                font-size: 24px;
                color: #3f3f3f;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 27px;
                  height: 27px;
                  margin-right: 12px;
                }
              }
            }
          }
        }
      }
    }

    .data-analysis {
      height: 362px;
      padding: 24px 40px 0;
      background: #ffffff;
      margin-bottom: 12px;

      .analysis-time {
        flex: 7;

        .select-time {
          display: flex;
          align-items: center;
        }
      }

      .tou {
        display: flex;
        align-items: center;

        .all-img {
          width: 382px;
          height: 20px;
        }

        .some-one {
          display: flex;
          align-items: center;
          margin-right: 28px;

          .color {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            border-radius: 2px;
          }

          .text {
            font-size: 14px;
          }
        }
      }

      .data-analysis-echarts {
        display: flex;
        height: 220px;

        #Echarts1 {
          width: 350px;
          height: 100%;
        }

        .echart-shell {
          flex: 1;
          height: 100%;
          position: relative;

          .echart {
            flex: 1;
            height: 100%;
          }

          .echart-legend-title {
            width: 100%;
            height: 20px;
            position: absolute;
            left: 0;
            bottom: 10px;
            z-index: 9999;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 16px;
              height: 16px;
              margin-right: 12px;
            }

            p {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #181818;
              line-height: 20px;
            }
          }
        }
      }
    }

    .table {
      background-color: #ffffff;
      padding: 24px 40px;
      // height: 449px;
      margin-bottom: 12px;

      .utilization-text {
        display: flex;
        align-items: center;

        .utilization-shell {
          width: 128px;
          height: 16px;
          background: rgba(24, 118, 255, 0.15);
          border-radius: 3px;
          margin-right: 10px;

          .utilization {
            height: 100%;
            background-color: #4993ff;
          }
        }
      }

      // &::v-deep .ant-table-body {
      // 	margin: 0;
      // }

      // &::v-deep .ant-table-thead {
      // 	background: rgba(97, 97, 97, 0.11);
      // }

      // &::v-deep .ant-table-thead>tr>th {
      // 	color: #181818 !important;
      // 	font-size: 16px;
      // 	padding: 4px 8px;
      // }

      // &::v-deep .ant-table-row {
      // 	color: #3F3F3F !important;
      // 	font-size: 14px;
      // 	padding: 4px 0;
      // }

      // &::v-deep .ant-table-row>td {
      // 	padding: 5px 8px;
      // }

      // &::v-deep .ant-table-row:nth-child(2n) {
      // 	background: rgba(97, 97, 97, 0.04);
      // }

      @import "../../assets/css/AppHome.scss";
    }

    .outage-base {
      background-color: #ffffff;
      padding: 24px 40px;
      // height: 572px;
      display: flex;
      flex-direction: column;

      .base-station-type {
        display: flex;
        align-items: center;

        span {
          font-size: 16px;
          margin-right: 12px;
        }
      }

      .outage-base-table {
        flex: 1;
        overflow: hidden;

        .some {
          display: flex;
          align-items: center;
          height: 28px;
          font-size: 14px;
          color: #3f3f3f;
          margin-bottom: 16px;

          .icon {
            width: 60px;
            height: 28px;
            margin-right: 32px;
          }

          .station_name {
            height: 28px !important;
            line-height: 28px !important;
            overflow: hidden;
            width: 290px;
          }

          .TYYY {
            padding: 0 16px;
            height: 28px !important;
            line-height: 28px !important;
            flex: 1;
            text-align: center;
            overflow: hidden;
          }

          .TYSD {
            width: 320px;
            height: 28px !important;
            line-height: 28px !important;
            overflow: hidden;
          }
        }

        .some:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>

<style scoped>
.swiper .ant-carousel >>> .slick-slider{
  width:100%;
}
.swiper .ant-carousel >>> .slick-slide {
  text-align: center;
  height: 350px;
  line-height: 350px;
  overflow: hidden;
  width:100%;
}
.swiper .ant-carousel >>> .slick-track{
  height: 350px;
  width:100%;
}
.swiper .slick-slide img {
  min-height:350px;
  height: 350px;
  display: block;
  width: 100%;
}

.swiper .ant-carousel >>> .custom-slick-arrow {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.swiper .ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}

.swiper .ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.swiper .ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>

<style lang="scss" scoped>
.uniform-title-shell {
  display: flex;
  align-items: center;

  .uniform-title {
    flex: 1;
    height: 24px;
    line-height: 24px;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .more {
    font-size: 14px;
    color: #999999;
    cursor: pointer;
  }
}

.unified-left-icon {
  display: flex;
  align-items: center;
  font-size: 16px;

  .border {
    width: 4px;
    height: 22px;
    margin-right: 10px;
    background-color: #027aff;
  }

  .text {
    flex: 1;
  }
}
</style>