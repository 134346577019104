<template>
	<div id="BaseSelect">
		<a-select :default-value="defaultValue" v-bind:style="{width: width}" @change="handleChange">
			<a-select-option v-for="(item) in baseData" :value="item.FieldBH" :key="item.FieldBH">
				{{item.FieldMC}}
			</a-select-option>
		</a-select>
	</div>
</template>

<script>
	import {
		http
	} from '../utils/Http.js';



	export default {
		name: 'BaseSelect',
		props: {
			type: Number,
			width: {
				type: String,
				default: '80px'
			}
		},
		data() {
			return {
				baseData: [],
				defaultValue: "全部"
			}
		},

		created() {
			this.SYS_DB_Dictionary();
		},

		methods: {

			//选择
			handleChange(value, info) {
				var me = this;
				// console.log(value);
				this.defaultValue = value;
				for (var i = 0; i < me.baseData.length; i++) {
					if (value == me.baseData[i].FieldBH) {
						me.$emit('baseChange', me.baseData[i], this.type);
					}
				}

			},

			SYS_DB_Dictionary() {
				var me = this;
				http({
					url: '/data/q30/AutoTable_Select.aspx?TableName=SYS_DB_Dictionary',
					data: {
						Module: 'BD',
						DataWhere: 'LX=8'
					},
					success(obj) {
						console.log(obj);
						if (obj.Data && obj.Data.length > 0) {
							obj.Data.unshift({
								FieldBH: 0,
								FieldMC: '全部'
							})

							// me.defaultValue = 0;
							// console.log(me.defaultValue);
							
							me.baseData = obj.Data;
						}
					},
					failure(obj) {},
					me
				})

			},


		}
	}
</script>

<style>
</style>
